import { parseISO, sub } from 'date-fns';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import {
  formatFlowHorizons,
  formatRuns,
  getRunWithSameDateOrTheLastOne,
} from 'src/services/data/utils';
import { isDev } from 'src/utils/utils';
import { useAuth } from '../auth/useAuth';
import useDataActions from './actions';
import { useDataDispatch, useDataState } from './context';
import useDataQueries from './queries';

let firstFetchForRunSelected = true;
let initialSelectedRunEffectDone = false;

export const useData = () => {
  const {
    user,
    contractGraphs,
    contractGraphNodes,
    contractsByCommodities,
    demandSelectedPeriod,
    selectedContract,
    flows,
    selectedFlow,
    recommendationProblemContracts,
    // flow, // TODO replace by "selectedFlow"
    modelingGroup,
    features,
    target,
    runs,
    outputs,
    selectedRun,
    prevSelectedRun,
    priceDriversSelectedHorizon,
    buyHoldHorizon,
    defaultBuyHoldHorizon,
    buyHoldVariationPrice,
    buyHoldData,
    timeSeries,
    drivers,
    flowHorizons,
    driverStats,
    currentPriceDrivers,
    windowPriceDrivers,
    currency,
    targetOverview,
    loadingStates,
    isTenantApp,
    logo,
    globalSnackBars,
    selectedDecisionFlow,
    contractHasProcurementFlow,
  } = useDataState();

  const { authenticatedRequest, username } = useAuth();

  const dispatch = useDataDispatch();

  const {
    setData,
    setMultiData,
    setSelectedRun,
    reset,
    setTargetOverview,
    setLoading,
    setIsTenantApp,
    addGlobalSnackBar,
    removeGlobalSnackBar,
  } = useDataActions(dispatch);

  const {
    getLogo,
    getLatestLogo,
    getContract,
    getContractsAndCommodities,
    getContractDeliveryDates,
    getFlowsWithProductionFlows,
    getProductionFlows,
    getRunOutputs,
    getTimeSerieData,
    getTimeSerieDataAndStats,
    getTimeSerieStats,
    getTimeSeriesUnit,
    getTrendsSummaryTask,
    getSummaryResult,
    getPriceCorridorsSummaryTask,
    getPriceDriversSummaryTask,
    getOneTimeSeries,
    getProductionRuns,
    getCurrency,
    getFlow,
    getFlowDrivers,
    getFlowHorizons,
    getCurrentPriceDrivers,
    getWindowPriceDrivers,
    getVolatilityHistorical,
    postBuyometerTask,
    getBuyometerResults,
    getContractGraphs,
    getUser,
    getUserSetting,
    setUserSetting,
    getIsTenantApp,
    getUnitsInstance,
    getBuyometerSummaryTask,
    getCurrentProductionFlow,
  } = useDataQueries(authenticatedRequest);

  const fetchInitialData = useCallback(() => {
    // Fetch User Object
    const promiseUser = getUser(username).then((_user) => {
      return {
        user: _user,
      };
    });

    /**
     * Get contracts graph
     */
    const promiseContractGraphs = getContractGraphs()
      .then((_contractHierarchy) => {
        return {
          contractGraphs: _contractHierarchy.graph,
          selectedContractNodes: [],
        };
      })
      .then((hierarchyObj) => {
        const findFirstContractID = (graph) => {
          const firstNode = graph[0];
          if (firstNode?.contract_id !== undefined) {
            return firstNode?.contract_id;
          } else {
            return findFirstContractID(firstNode.children);
          }
        };
        const contractId = findFirstContractID(hierarchyObj.contractGraphs);
        return getContract(contractId).then((_contract) => {
          return {
            selectedContract: _contract,
            ...hierarchyObj,
          };
        });
      });

    /**
     * Get the contracts and the commodities
     */
    const promiseContractsAndCommodities = getContractsAndCommodities().then(
      (contractsAndCommodities) => {
        const { contractsByCommodities: _contractsByCommodities } =
          contractsAndCommodities;

        return {
          contractsByCommodities: _contractsByCommodities,
        };
      }
    );

    /**
     * Check if Application is Tenant App or not
     */
    /*  const promiseIsTenantApp = getIsTenantApp()
      .then((res) => {
        res === 200 && setIsTenantApp(true);
      })
      .catch((e) => console.log(e)); */

    /**
     * Get Logo
     */
    const promiseGetLogo = getLogo().then((res) => {
      if (res.results.length) {
        const logo = getLatestLogo()
          .then((logo) => {
            // Obtain a blob: URL for the image data.
            const arrayBufferView = new Uint8Array(logo.data);
            const blob = new Blob(
              [arrayBufferView],
              logo.image ? { type: 'image/jpeg' } : { type: 'image/svg+xml' }
            );

            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            return {
              logo: {
                data: imageUrl,
                format: logo.headers['content-type'],
              },
            };
          })
          .catch((e) => {
            console.error(e);
            return {
              logo: 'default',
            };
          });
        return logo;
      } else {
        return {
          logo: 'default',
        };
      }
    });

    Promise.all([
      promiseUser,
      promiseContractGraphs,
      promiseContractsAndCommodities,
      //promiseIsTenantApp,
      promiseGetLogo,
    ])
      .then((promisedValues) => {
        // merge all the objects returned by the promises above
        setMultiData(Object.assign({}, ...promisedValues));
        initialSelectedRunEffectDone = false;
      })
      .catch((error) => {
        if (isDev) {
          console.error('fetchInitialData', error);
        }
        throw error;
      });
  }, [
    getUser,
    username,
    getContractGraphs,
    getContractsAndCommodities,
    getLogo,
    getLatestLogo,
    getContract,
    setMultiData,
  ]);

  const onComputeBuyHoldIndicator = useCallback(
    (
      flowId,
      targetId,
      maxPriceVar,
      buyHoldHorizon,
      startDate,
      endDate,
      defaultWindow
    ) => {
      setLoading('onComputeBuyHoldIndicator', true);
      // Post the order to strat computing buyometer results and get the task ID
      postBuyometerTask(
        flowId,
        targetId,
        maxPriceVar,
        buyHoldHorizon,
        startDate,
        endDate,
        defaultWindow
      ).then((buyometerTaskId) => {
        // Get the results of the task
        if (
          buyometerTaskId &&
          buyometerTaskId.status === 404 &&
          buyometerTaskId.data.detail === 'Not enough data.'
        ) {
          setMultiData({
            buyHoldData: [],
          });
          setLoading('onComputeBuyHoldIndicator', false);
          return;
        }
        getBuyometerResults(flowId, buyometerTaskId.computing_task_id).then(
          (_buyHoldData) => {
            setMultiData({
              buyHoldData: _buyHoldData,
            });
            setLoading('onComputeBuyHoldIndicator', false);
          }
        );
      });
    },
    [setLoading, postBuyometerTask, getBuyometerResults, setMultiData]
  );

  const getTrendsSummary = useCallback(
    async (reportType) => {
      setLoading('getTrendsSummary', true);
      const runId = selectedRun?.id;
      const target_contract =
        selectedContract?.series || selectedContract?.series_id;
      try {
        const taskId = await getTrendsSummaryTask(
          runId,
          target_contract,
          reportType
        );
        const isAlreadyGenerated = taskId?.data?.is_generated;
        const result = await getSummaryResult(
          runId,
          taskId?.data?.computing_task_id
        );
        setLoading('getTrendsSummary', false);

        return { result: result, isAlreadyGenerated: isAlreadyGenerated };
      } catch (error) {
        console.error('error', error);
        setLoading('getTrendsSummary', false);
        setLoading('getTrendsSummaryResult', false);
        throw error;
      }
    },
    [
      setLoading,
      selectedRun?.id,
      selectedContract,
      getTrendsSummaryTask,
      getSummaryResult,
    ]
  );
  const getPriceCorridorsSummary = useCallback(async () => {
    setLoading('getPriceCorridorsSummary', true);
    const runId = selectedRun?.id;
    const target_contract =
      selectedContract?.series || selectedContract?.series_id;
    try {
      const taskId = await getPriceCorridorsSummaryTask(runId, target_contract);
      const isAlreadyGenerated = taskId?.data?.is_generated;
      if (!isAlreadyGenerated) {
        const generatingSnackBar = {
          id: globalSnackBars.length > 0 ? globalSnackBars.length + 1 : 1,
          message: 'Generating summary (it usually takes 20 to 40 seconds)...',
          autoHideDuration: 5000,
        };
        addGlobalSnackBar(generatingSnackBar);
      }
      const result = await getSummaryResult(
        runId,
        taskId?.data?.computing_task_id
      );
      setLoading('getPriceCorridorsSummary', false);
      const newSummaryResult = {
        id: globalSnackBars.length > 0 ? globalSnackBars.length + 1 : 1,
        success: true,
        type: 'gptSummary',
        contract: selectedContract,
        message: `The price corridors summary for ${selectedContract?.name} is ready.`,
        content: result,
      };

      if (!isAlreadyGenerated) {
        addGlobalSnackBar(newSummaryResult);
      }
      return { result: result, isAlreadyGenerated: isAlreadyGenerated };
    } catch (error) {
      console.error('error', error);
      setLoading('getPriceCorridorsSummary', false);
      setLoading('getPriceCorridorsSummaryResult', false);
      throw error;
    }
  }, [
    setLoading,
    selectedRun?.id,
    selectedContract,
    getPriceCorridorsSummaryTask,
    getSummaryResult,
    globalSnackBars.length,
    addGlobalSnackBar,
  ]);

  const getPriceDriversSummary = useCallback(async () => {
    setLoading('getPriceDriversSummary', true);
    const runId = selectedRun?.id;
    const target_contract =
      selectedContract?.series || selectedContract?.series_id;
    try {
      const taskId = await getPriceDriversSummaryTask(runId, target_contract);
      const isAlreadyGenerated = taskId?.data?.is_generated;
      const result = await getSummaryResult(
        runId,
        taskId?.data?.computing_task_id
      );
      setLoading('getPriceDriversSummary', false);

      return { result: result, isAlreadyGenerated: isAlreadyGenerated };
    } catch (error) {
      console.error('error', error);
      setLoading('getPriceDriversSummary', false);
      throw error;
    }
  }, [
    getSummaryResult,
    getPriceDriversSummaryTask,
    selectedContract,
    selectedRun?.id,
    setLoading,
  ]);

  const getBuyometerSummary = useCallback(async () => {
    setLoading('getBuyometerSummary', true);
    const runId = selectedRun?.id;
    const target_contract =
      selectedContract?.series || selectedContract?.series_id;
    const endDate = moment(selectedRun?.production_date).format(
      'YYYY-MM-DDThh:mm:ss[Z]'
    );
    const startDate = moment(endDate)
      .subtract(30, 'day')
      .format('YYYY-MM-DDThh:mm:ss[Z]');

    try {
      const taskId = await getBuyometerSummaryTask(
        runId,
        target_contract,
        startDate,
        endDate,
        buyHoldHorizon?.value
      );
      const isAlreadyGenerated = taskId?.data?.is_generated;
      if (!isAlreadyGenerated) {
        const generatingSnackBar = {
          id: globalSnackBars.length > 0 ? globalSnackBars.length + 1 : 1,
          message: 'Generating summary (it usually takes 20 to 40 seconds)...',
          autoHideDuration: 5000,
        };

        addGlobalSnackBar(generatingSnackBar);
      }
      let result = await getSummaryResult(
        runId,
        taskId?.data?.computing_task_id
      );
      result =
        `On the ${moment(selectedRun.production_date).format(
          'DD/MM/YYYY'
        )}, for a decision horizon equal to ${buyHoldHorizon.value} day(s):
  \n` + result;
      setLoading('getBuyometerSummary', false);
      const newSummaryResult = {
        id: globalSnackBars.length > 0 ? globalSnackBars.length + 1 : 1,
        success: true,
        type: 'gptSummary',
        contract: selectedContract,
        message: `The buyometer summary for ${selectedContract?.name} is ready.`,
        content: result,
      };

      if (!isAlreadyGenerated) {
        addGlobalSnackBar(newSummaryResult);
      }
      return { result: result, isAlreadyGenerated: isAlreadyGenerated };
    } catch (error) {
      console.error('error', error);
      setLoading('getBuyometerSummary', false);
      throw error;
    }
  }, [
    addGlobalSnackBar,
    buyHoldHorizon?.value,
    getBuyometerSummaryTask,
    getSummaryResult,
    globalSnackBars.length,
    selectedContract,
    selectedRun?.id,
    selectedRun?.production_date,
    setLoading,
  ]);

  const onRunSelected = useCallback(async () => {
    setLoading('onRunSelected', true);
    if (!firstFetchForRunSelected) {
      window.isLoadingAfterRunSelected = true; // TODO temp hack (setLoading('onRunSelected')) is detected a little too late by FullChartPriceCorridords
    }

    const flowId = selectedRun?.flow;
    const runId = selectedRun?.id;
    const target_contract =
      selectedContract?.series || selectedContract?.series_id;

    if (!runId) {
      // This case happens when onRunSelected() is called within onContractSelected at launch. The selectedRun is not yet defined
      return;
    }

    // Get flow long term horizon threshold
    const flowDetails = await getFlow(flowId);
    const flowLongTermHorizonThreshold =
      flowDetails.flow_info.long_term_horizon_threshold;

    // Outputs
    return getRunOutputs(
      runId,
      target_contract,
      flowLongTermHorizonThreshold
    ).then((_outputs) => {
      let _currentPriceDrivers_promise;
      let _windowPriceDrivers_promises = [];

      // Current price drivers
      if (_outputs.featureImportances && priceDriversSelectedHorizon?.value) {
        // getCurrentPriceDrivers
        _currentPriceDrivers_promise = getCurrentPriceDrivers(
          flowId,
          runId,
          _outputs.featureImportances.id
        );

        // getWindowPriceDrivers
        // "Window" price drivers
        const endDate = parseISO(selectedRun?.production_date);
        const windowIntervals = [
          { period: { weeks: 1 }, label: 'Last 1W' },
          { period: { months: 1 }, label: 'Last 1M' },
          { period: { months: 3 }, label: 'Last 3M' },
          { period: { years: 1 }, label: 'Last 1Y' },
        ];

        _windowPriceDrivers_promises = windowIntervals.map((interval) => {
          return getWindowPriceDrivers(
            flowId,
            runId,
            _outputs.featureImportances.id,
            sub(endDate, interval.period).toISOString()
          ).then((priceDrivers) => {
            return { label: interval.label, priceDrivers: priceDrivers };
          });
        });
      }

      const _flowHorizons_promise = getFlowHorizons(selectedRun.flow).then(
        (flowHorizons) => {
          return {
            flowHorizons: flowHorizons,
          };
        }
      );
      const _drivers_promise = getFlowDrivers(selectedRun.flow).then(
        (flowDrivers) => {
          return {
            drivers: flowDrivers,
          };
        }
      );

      if (!firstFetchForRunSelected) {
        window.isLoadingAfterRunSelected = false; // TODO temp hack (setLoading('onRunSelected')) is detected a little too late by FullChartPriceCorridords
      }
      if (selectedRun?.production_date && prevSelectedRun !== selectedRun) {
        const endDate = selectedRun?.production_date;
        const startDate = moment(endDate).subtract(30, 'day').toISOString();
        onComputeBuyHoldIndicator(
          flowId,
          target_contract,
          0,
          buyHoldHorizon?.value,
          startDate,
          endDate,
          '30D'
        );
      }
      setMultiData({
        prevSelectedRun: selectedRun,
      });

      return Promise.all([
        _currentPriceDrivers_promise,
        ..._windowPriceDrivers_promises,
        _flowHorizons_promise,
        _drivers_promise,
      ]).then((values) => {
        const _currentPriceDrivers = values[0];
        const _windowPriceDrivers = values.slice(1, 5);
        const data = Object.assign({}, ...values);
        const formatedFlowHorizons = formatFlowHorizons(data.flowHorizons); // rename labels

        setMultiData({
          outputs: _outputs,
          currentPriceDrivers: _currentPriceDrivers,
          windowPriceDrivers: _windowPriceDrivers,
          buyHoldVariationPrice: null,
          drivers: data.drivers,
          flowHorizons: formatedFlowHorizons,
        });

        setLoading('onRunSelected', false);
        firstFetchForRunSelected = false;
      });
    });
  }, [
    setLoading,
    prevSelectedRun,
    selectedRun,
    selectedContract?.series,
    selectedContract?.series_id,
    getFlow,
    getRunOutputs,
    setMultiData,
    priceDriversSelectedHorizon,
    getFlowHorizons,
    getFlowDrivers,
    getCurrentPriceDrivers,
    getWindowPriceDrivers,
    onComputeBuyHoldIndicator,
    buyHoldHorizon?.value,
  ]);

  const hasProcurementFlow = useCallback(
    async (contractId) => {
      setLoading('hasProcurementFlow', true);
      const res = await getCurrentProductionFlow(contractId);
      setLoading('hasProcurementFlow', false);
      if (res.length) return true;
      else return false;
    },
    [getCurrentProductionFlow, setLoading]
  );

  const onContractSelected = useCallback(
    (_contract) => {
      setLoading('onContractSelected', true);

      // Check if the selected contract has a procurement flow (modelled contract)
      const promise_hasProcurementFlow = hasProcurementFlow(_contract.id).then(
        (res) => {
          return {
            contractHasProcurementFlow: res,
          };
        }
      );

      const _currency_promise = getCurrency(_contract?.currency).then((res) => {
        return {
          currency: res,
        };
      });

      const _runs_promise = getProductionRuns(_contract?.id).then((runs) => {
        return {
          runs: runs,
        };
      });

      const _target_promise = getOneTimeSeries(_contract.series)
        .then((target) => {
          return {
            target: target,
          };
        })
        .then((targetObj) => {
          return getTimeSerieData(_contract.series).then((_targetData) => {
            return {
              target: {
                ...targetObj.target,
                data: _targetData.data,
              },
            };
          });
        });

      Promise.all([promise_hasProcurementFlow]).then((data) => {
        setMultiData({
          contractHasProcurementFlow: data[0].contractHasProcurementFlow,
        });

        if (data[0].contractHasProcurementFlow) {
          const _flows_promise = getFlowsWithProductionFlows(_contract.id)
            .then((_flows) => {
              return {
                flows: _flows,
              };
            })
            .then(async (flowsObj) => {
              if (flowsObj.flows.length) {
                const _selectedFlow = flowsObj.flows[0];
                const flowDetails = await getFlow(
                  _selectedFlow?.production_flow
                );
                const longTermHorizonThreshold =
                  flowDetails?.flow_info?.long_term_horizon_threshold;
                _selectedFlow.long_term_horizon_threshold =
                  longTermHorizonThreshold;
                const _flowHorizons_promise = getFlowHorizons(
                  _selectedFlow.production_flow
                ).then((flowHorizons) => {
                  return {
                    flowHorizons: flowHorizons,
                  };
                });
                const _drivers_promise = getFlowDrivers(
                  _selectedFlow.production_flow
                ).then((flowDrivers) => {
                  return {
                    drivers: flowDrivers,
                  };
                });
                const _productionFlows_promise = getProductionFlows(
                  _contract.id
                ).then((productionFlows) => {
                  return {
                    productionFlows: productionFlows,
                  };
                });

                return Promise.all([
                  _flowHorizons_promise,
                  _drivers_promise,
                  _productionFlows_promise,
                ]).then((values) => {
                  const data = Object.assign({}, ...values);
                  return {
                    ...data,
                    ...flowsObj,
                    selectedFlow: _selectedFlow,
                  };
                });
              }
            });

          const promises = [_runs_promise, _flows_promise];
          Promise.all(promises)
            .then((values) => {
              const data = Object.assign({}, ...values);
              const formatedFlowHorizons = formatFlowHorizons(
                data.flowHorizons
              );
              const _priceDriversSelectedHorizon = formatedFlowHorizons[0];
              const longTermHorizonThreshold =
                data?.selectedFlow?.long_term_horizon_threshold;
              const shortTermFormatedFlowHorizons =
                formatedFlowHorizons?.length > 0
                  ? formatedFlowHorizons.filter((h) => {
                      return h.value < longTermHorizonThreshold;
                    })
                  : [];
              const _buyHoldHorizon = longTermHorizonThreshold
                ? shortTermFormatedFlowHorizons[
                    shortTermFormatedFlowHorizons.length - 1
                  ]
                : formatedFlowHorizons[formatFlowHorizons.length - 1];
              const formattedRuns = formatRuns(data.runs);

              /**
               * Handle the case when the selectedRun doesn't exist anymore in the new runs
               */
              let newSelectedRun = null;

              const selectedRunId = selectedRun?.id;
              const isSelectedRunStillExists = !!formattedRuns.find(
                (r) => r.id === selectedRunId
              );

              if (selectedRunId && !isSelectedRunStillExists) {
                newSelectedRun = getRunWithSameDateOrTheLastOne(
                  formattedRuns,
                  selectedRun.production_date
                );
              } else if (selectedRunId) {
                newSelectedRun = { ...selectedRun };
              }

              setMultiData({
                selectedContract: _contract,
                flows: data.flows && !data.flows.error ? data.flows : [],
                selectedFlow: data.selectedFlow,
                flowHorizons: formatedFlowHorizons,
                priceDriversSelectedHorizon: _priceDriversSelectedHorizon,
                buyHoldHorizon: _buyHoldHorizon,
                defaultBuyHoldHorizon: _buyHoldHorizon,
                buyHoldVariationPrice: null,
                runs: formattedRuns,
                drivers: data.drivers,
                selectedRun: newSelectedRun,
              });
            })
            .catch((e) => {
              if (isDev) {
                console.log(e);
              }
              setLoading('onContractSelected', false);
              throw e;
            });
        }

        const promises = [_currency_promise, _target_promise];
        Promise.all(promises)
          .then((values) => {
            const data = Object.assign({}, ...values);
            setMultiData({
              currency: data.currency,
              target: data.target,
            });
          })
          .catch((e) => {
            if (isDev) {
              console.log(e);
            }
            setLoading('onContractSelected', false);
            throw e;
          });
      });
      setLoading('onContractSelected', false);
    },
    [
      setLoading,
      hasProcurementFlow,
      getCurrency,
      getProductionRuns,
      setMultiData,
      getFlowsWithProductionFlows,
      getOneTimeSeries,
      getFlow,
      getFlowHorizons,
      getFlowDrivers,
      getProductionFlows,
      getTimeSerieData,
      selectedRun,
    ]
  );

  const onBuyHoldHorizonSelected = useCallback(
    async (_buyHoldHorizon) => {
      setLoading('onBuyHoldHorizonSelected', true);
      setMultiData({
        buyHoldHorizon: _buyHoldHorizon,
      });
      setLoading('onBuyHoldHorizonSelected', false);
    },
    [setLoading, setMultiData]
  );

  const onBuyHoldVariationPriceChange = useCallback(
    async (_buyHoldVariationPrice) => {
      setLoading('onBuyHoldVariationPriceChange', true);
      setMultiData({
        buyHoldVariationPrice: _buyHoldVariationPrice,
      });
      setLoading('onBuyHoldVariationPriceChange', false);
    },
    [setLoading, setMultiData]
  );

  const updateTargetOverview = useCallback(
    async (targetId, startDate, endDate) => {
      setLoading('onTargetUpdate', true);
      getTimeSerieStats(targetId, startDate, endDate)
        .then((timeSerieStats) => {
          setTargetOverview({
            lastPrice: timeSerieStats?.stats.latest_value || 0,
            priceChange: timeSerieStats?.stats?.diff || 0,
            changeRate: timeSerieStats?.stats?.pct_change,
          });
          setLoading('onTargetUpdate', false);
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [getTimeSerieStats, setLoading, setTargetOverview]
  );

  const getTimeSeriesUnitById = useCallback(
    async (timeSeriesId) => {
      const result = await getTimeSeriesUnit(timeSeriesId);
      return result;
    },
    [getTimeSeriesUnit]
  );

  /**
   * Effect
   */

  // Set the selected "run"
  useEffect(() => {
    if (!selectedRun && runs?.length && !initialSelectedRunEffectDone) {
      initialSelectedRunEffectDone = true; // needed to avoid to pass 10 times here instead of 1
      const lastRun = runs[runs.length - 1];
      setSelectedRun(lastRun);
    }
  }, [runs, selectedRun, setMultiData, setSelectedRun]);

  return {
    // State
    user,
    contractGraphs,
    contractGraphNodes,
    contractsByCommodities,
    demandSelectedPeriod,
    selectedContract,
    flows,
    selectedFlow,
    recommendationProblemContracts,
    // flow, // TODO difference between "selectedFlow" (new) and "flow"
    modelingGroup,
    features,
    target,
    // contract, // TODO remove?
    // commodity, // TODO remove?
    runs,
    selectedRun,
    priceDriversSelectedHorizon,
    buyHoldHorizon,
    defaultBuyHoldHorizon,
    buyHoldVariationPrice,
    buyHoldData,
    outputs,
    timeSeries,
    drivers,
    flowHorizons,
    driverStats,
    currentPriceDrivers,
    windowPriceDrivers,
    currency,
    targetOverview,
    loadingStates,
    isTenantApp,
    logo,
    globalSnackBars,
    selectedDecisionFlow,
    contractHasProcurementFlow,
    // Actions
    fetchInitialData,
    setData,
    setMultiData,
    onContractSelected,
    setSelectedRun,
    onRunSelected,
    getContract,
    getContractDeliveryDates,
    getFlow,
    getTimeSerieData,
    getTimeSerieDataAndStats,
    getTimeSerieStats,
    getTimeSeriesUnitById,
    getTrendsSummary,
    getPriceCorridorsSummary,
    getPriceDriversSummary,
    getBuyometerSummary,
    getVolatilityHistorical,
    onBuyHoldHorizonSelected,
    onBuyHoldVariationPriceChange,
    onComputeBuyHoldIndicator,
    reset,
    setTargetOverview,
    addGlobalSnackBar,
    removeGlobalSnackBar,
    postBuyometerTask,
    getBuyometerResults,
    getUserSetting,
    getUnitsInstance,
    setUserSetting,
    updateTargetOverview,
    getIsTenantApp,
    setIsTenantApp,
    hasProcurementFlow,
  };
};
